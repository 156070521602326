
import {defineComponent, onMounted, ref} from 'vue'
import {ChartCode, ChartType} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import Chart from "@/views/chart/Chart.vue";
import BaseClientSelect from "@/views/company/BaseClientSelect.vue";

export default defineComponent({
  name: "CallCenter",
  components: { BaseClientSelect,  Chart, BaseRadio},
  setup() {
    const days = ref( 30);
    const clientId = ref('');
    const mounted = ref(false);
    onMounted(() => {
      mounted.value = true;
      setCurrentPageBreadcrumbsWithParams("Call Center Dashboard", [])
    })
    return {
      clientId,
      days,
      ChartCode,
      ChartType,
      mounted,
    }
  }
})
