import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-3" }
const _hoisted_8 = { class: "col-md-3" }
const _hoisted_9 = { class: "col-md-3" }
const _hoisted_10 = { class: "col-md-3" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-6" }
const _hoisted_13 = { class: "col-md-6" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-6" }
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-md-6" }
const _hoisted_19 = { class: "col-md-6" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-md-6" }
const _hoisted_22 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseRadio = _resolveComponent("BaseRadio")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_BaseClientSelect = _resolveComponent("BaseClientSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, " Summary " + _toDisplayString(_ctx.days) + " days ", 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BaseRadio, {
            data: [
                {value:'1', name:'Day'},
                {value:'7', name:'Week'},
                {value:'30', name:'Month'},
                {value:'365', name:'Year'},
              ],
            modelValue: _ctx.days,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.days) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_IN_TILE, type :_ctx.ChartType.TILE, title:'#Inbound Calls', params: {days:_ctx.days, company_id:_ctx.clientId}, unit:''}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_OUT_TILE, type :_ctx.ChartType.TILE, title:'#Outbound Calls', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:''}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_DUR_TILE, type :_ctx.ChartType.TILE, title:'#Call Duration', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:''}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_AUDIT_TILE, type :_ctx.ChartType.TILE, title:'#Audited Calls', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:''}
            }, null, 8, ["params"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_CHART, type :_ctx.ChartType.COLUMN, title:'Phone Calls Trends', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:'Calls'}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_DUR_CHART, type :_ctx.ChartType.COLUMN, title:'Phone Calls Duration', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:'Mins'}
            }, null, 8, ["params"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_HOUR_CHART, type :_ctx.ChartType.COLUMN, title:'Phone Calls Volume by Hour', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:'Calls'}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_MONTH_CHART, type :_ctx.ChartType.COLUMN, title:'Phone Calls Volume by Month', params: {days:'365', company_id: _ctx.clientId}, unit:'Calls'}
            }, null, 8, ["params"])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_CLIENT_SUMMARY_CHART, type :_ctx.ChartType.PIE, title:'Calls by Client', params: {days:_ctx.days, company_id:_ctx.clientId}, unit:'Call(%)'}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_Chart, {
              params: {code :_ctx.ChartCode.CALLS_CLIENT_CHART, type :_ctx.ChartType.COLUMN, title:'Calls By Client', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:'Calls'}
            }, null, 8, ["params"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_Chart, {
              params: {code :'DB_CALL_CENTER_LANG_CHART', type :_ctx.ChartType.COLUMN, title:'Calls by Language', params: {days:_ctx.days, company_id:_ctx.clientId}, unit:'Call(%)'}
            }, null, 8, ["params"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_Chart, {
              params: {code :'DB_CALL_CENTER_LANG_PIE', type :_ctx.ChartType.PIE, title:'Calls By Language', params: {days:_ctx.days, company_id: _ctx.clientId}, unit:'Calls'}
            }, null, 8, ["params"])
          ])
        ])
      ])
    ]),
    (_ctx.mounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#toolbar-actions"
        }, [
          _createVNode(_component_BaseClientSelect, {
            modelValue: _ctx.clientId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clientId) = $event))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}